import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useUserDataQuery } from "./services/accountsSlice";
import useAuthListener from "./utils/authListener";
import LoadingPlaceholder from "./components/LoadingPlaceholder";
import { OrderType, TransferType } from "./types";
import useTransfers from "./services/transfers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Receipt } from "./components/Receipt";
import { debugPrint } from "./utils/helpers";
import Footer from "./Footer";


const Order = (): JSX.Element => {
    const params = useParams()
    const { uid, phoneNumber } = useAuthListener()
    const { data } = useUserDataQuery(uid)
    const { createTransfer } = useTransfers()
    const { accounts, defaultAccount, email, legalName, orders } = data ?? { accounts: {}, orders: {} }

    const [paymentInProgress, setPaymentInProgress] = useState(false)
    const [order, setOrder] = useState<OrderType | undefined>()
    const [transfers, setTransfers] = useState<TransferType[]>([])
    const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>()

    const lastPartOf = (uuid: string) => uuid.split('-').pop()

    const prettyDate = (timestamp: number) => new Date(timestamp).toLocaleDateString()

    const handlePayNow = async () => {
        setPaymentInProgress(true)
        const account = accounts[selectedAccountId ?? ""]
        if (!order || !account || !legalName) {
            debugPrint("Can't initiate a transfer: Invalid order, account or legal name is missing", 'error')
            return
        }

        function shortnedDescription(orderPlan: string): string {
            switch (orderPlan) {
                case 'Triumph Monthly':
                    return 'Triumph Mo'
                case 'Standard Monthly':
                    return 'Standard Mo'
                case 'Triumph Weekly':
                    return 'Triumph Wk'
                case 'Standard Weekly':
                    return 'Standard Wk'
                default:
                    return "ParlayP Sports"
            }
        }

        const transferData = {
            accessToken: account.accessToken!,
            amount: order.amount.toFixed(2).toString(),
            description: shortnedDescription(order.plan),           // Plaid has a limitation to 15 characters for description
            email,
            legalName,
            orderId: order.id,
            plaidAccountId: account.accountId,
            uid,
        }

        await createTransfer(transferData)
        setPaymentInProgress(false)
    }

    useEffect(() => {
        if ('orderId' in params) {
            const currentOrder = orders[params.orderId!]
            if (!!currentOrder) {
                setOrder(currentOrder)
                const transfers = Object.keys(currentOrder.transfers ?? {}).map((key) => currentOrder.transfers![key]).sort((a, b) => b.createdAt - a.createdAt)
                setTransfers(transfers)
            }
        }
        if (!selectedAccountId)
            if (!!defaultAccount)
                setSelectedAccountId(defaultAccount)
            else
                setSelectedAccountId(Object.keys(accounts)[0])
    }, [accounts, defaultAccount, orders])

    const accountsSelectOptions = Object.keys(accounts).map((key) => accounts[key]).map((account) => (
        <option key={account.accountId} value={account.accountId}> [**** {account.mask}] {account.bankName} </option>
    ))

    const transfersList = transfers.map((transfer) => {
        const status = (transfer.status === 'failed')
            ? transfer.rationale ?? transfer.status
            : transfer.status
        return <tr key={transfer.id}>
            <td>{prettyDate(transfer.createdAt)}</td>
            <td>{transfer.description}</td>
            <td>${transfer.amount.toFixed(2)}</td>
            <td>{status}</td>
        </tr>
    })

    const selectChangeHandler = (ev: React.ChangeEvent<HTMLSelectElement>) => setSelectedAccountId(ev.target.value)

    const settledOrPendingTransferExists = (transfers.find((transfer) => transfer.status === 'settled' || transfer.status === 'pending') !== undefined)
    const isPayable = (
        !!selectedAccountId &&
        !!legalName &&
        order?.status !== 'paid' &&
        !settledOrPendingTransferExists
    )


    const invoiceButton = (): JSX.Element => {
        const paymentTransfer = transfers.find((transfer) => transfer.status === 'settled')

        if (order?.status !== 'paid' || !paymentTransfer || !legalName)
            return <></>

        const receipt = <Receipt
            amount={order.amount}
            legalName={legalName}
            phoneNumber={phoneNumber}
            product={order.plan}
            transferId={paymentTransfer.id}
            transferTimestamp={paymentTransfer.updatedAt ?? paymentTransfer.createdAt}
            orderId={order.id}
        />

        return <div className="d-flex justify-content-end">
            <PDFDownloadLink className="btn btn-secondary px-5" document={receipt} fileName={`PPSN-${lastPartOf(order.id)}.pdf`}>
                {({ blob, url, loading, error }) =>
                    loading ? 'Processing...' : 'Download receipt'
                }
            </PDFDownloadLink>
        </div>
    }

    if (Object.keys(orders).length === 0) return <LoadingPlaceholder />

    if (!order) return <h3>Order ID is invalid</h3>

    return <div id="order" className="d-flex flex-column" style={{ minHeight: '100vh' }}>
        <div className="container d-flex flex-grow-1 flex-column">
            <div className="mb-2 mt-0">
                <a href="/" className="fs-4 text-white text-decoration-none fw-bold">← Dashboard</a>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-flex justify-content-between">
                        <h3 className="mb-5 d-inline">Order: #{lastPartOf(params.orderId ?? '')}</h3>
                        <h3 className="mb-5 d-inline">Status: {order.status.toUpperCase()}</h3>
                    </div>
                    <div className="row">
                        <div className="col-sm-8 text-center text-md-start mt-4">
                            <h4 className="card-title mt-0">{order.plan}</h4>
                            <h5 style={{ color: "#cd201f" }}>${order.amount.toFixed(2)}</h5>
                        </div>
                        <div className="col-sm-4 mt-4">
                            {isPayable &&
                                <div>
                                    <select id="accountSelect" className="form-select mb-2" value={selectedAccountId} onChange={selectChangeHandler} >
                                        {accountsSelectOptions}
                                    </select>
                                    <button className="btn btn-primary w-100 my-3" onClick={handlePayNow} disabled={paymentInProgress} >{paymentInProgress ? 'Processing...' : 'Pay Now'}</button>
                                    <div className="text-muted fs-6 px-4" style={{ textAlign: 'justify' }}>
                                        <p>ℹ️ Payment for this order is a one-time ACH transfer. By pressing the 'PAY NOW' button you authorize The Sports Newsletter, LLC to process the payment.<br /><br />Once you authorize this payment, it cannot be revoked. If you need to stop the transfer, please contact your bank for stop payment options.</p>
                                    </div>
                                </div>
                            }
                            {invoiceButton()}
                        </div>
                    </div>
                    <div className="my-5">

                        <hr />
                        {transfers.length > 0 &&
                            <div>
                                <h3>Transfers</h3>
                                {(transfers[0].status === 'pending') &&
                                    <div className="alert alert-success">
                                        <p className="mb-0">
                                            <strong>The bank transfer has been initiated.</strong>
                                            <br />
                                            Your account will be automatically upgraded shortly. When your premium account is activated you will be notified in the app and will have access to premium picks.
                                        </p>
                                    </div>
                                }
                                <table className="table">
                                    {/* <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead> */}
                                    <tbody>
                                        {transfersList}
                                    </tbody>
                                </table>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default Order