import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';
import { getFirebase } from './services/firebase';
import { LinkProvider } from './services/link';
import { TransfersProvider } from './services/transfers';
import { OrdersProvider } from './services/orders';
import { ErrorsProvider } from './services/errors';
import './index.css';

getFirebase() // initialize firebase

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Provider store={store}>
    <ErrorsProvider>
      <LinkProvider>
        <OrdersProvider>
          <TransfersProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </TransfersProvider>
        </OrdersProvider>
      </LinkProvider >
    </ErrorsProvider>
  </Provider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
