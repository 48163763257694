import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "./hooks"
import { Product } from "./types"
import { setLegalName, setDefaultAccount, useUserDataQuery } from "./services/accountsSlice"
import LoadingPlaceholder from "./components/LoadingPlaceholder"
import useAuthListener from "./utils/authListener"
import { useLink } from "./services"
import LaunchLink from "./components/LaunchLink"
import useOrders from "./services/orders"
import ProductView from "./components/ProductView"
import Footer from "./Footer"
import { debugPrint } from "./utils/helpers"


/// When changing prices make sure to update the Firebase Function that creates
/// transfers. This function sets the transfer metadata 'plan' attribute based
/// on the price.
const PRODUCTS: Product[] = [
    { id: 1, name: 'Triumph Monthly', price: 1000 },
    { id: 2, name: 'Standard Monthly', price: 500 },
    { id: 3, name: 'Triumph Weekly', price: 300 },
    { id: 4, name: 'Standard Weekly', price: 150 },
]

const Dashboard: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { uid } = useAuthListener()
    const { data, isLoading } = useUserDataQuery(uid)
    const { accounts, legalName, orders, defaultAccount } = data ?? { accounts: {}, orders: {} }
    const [localError, setLocalError] = useState<string | null>(null)
    const [selectedProduct, setSelectedProduct] = useState<Product>(PRODUCTS[0])
    const [placingOrder, setPlacingOrder] = useState(false)
    const [name, setName] = useState('')
    const { placeOrder } = useOrders()

    const [token, setToken] = useState('')
    const { linkTokens, generateLinkToken } = useLink()

    const initiateLink = async () => await generateLinkToken(uid, null)

    const orderButtonHandler = async () => {
        try {
            setPlacingOrder(true)
            const orderId = await placeOrder({ name: selectedProduct.name, amount: selectedProduct.price })
            if (orderId)
                navigate(`/orders/${orderId}`)
        } catch (error) {
            debugPrint(JSON.stringify(error), 'error')
            setLocalError("An internal server error has occurred. Please contact support if the issue persists.")
        }
        setPlacingOrder(false)
    }

    useEffect(() => {
        setToken(linkTokens.byUser[uid])
    }, [linkTokens, uid])

    const navigateToOrder = (ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const orderId = ev.currentTarget.getAttribute('data-order-id')
        navigate(`/orders/${orderId}`)
    }

    const ordersView = (): JSX.Element => {
        const ordersList = Object.keys(orders).map((key) => orders[key])
        return <tbody>
            {ordersList.sort((a, b) => b.timestamp - a.timestamp).map((order) => (
                <tr key={order.id} data-order-id={order.id} onClick={navigateToOrder} role="button">
                    <td>{prettyDate(order.timestamp)}</td>
                    <td>{order.plan}</td>
                    <td>{order.status}</td>
                </tr>
            ))}
        </tbody>
    }

    const prettyDate = (timestamp: number) => new Date(timestamp).toLocaleDateString()

    const productsView = (): JSX.Element => {
        return <div className="row">
            {PRODUCTS.map((product) => <ProductView
                key={product.id}
                isSelected={product.id === selectedProduct.id}
                product={product}
                selectHandler={setSelectedProduct}
            />)}
        </div>
    }

    const accountsView = (): JSX.Element => {
        const listItems = Object.keys(accounts)
            .map((key) => accounts[key])
            .map((account) => {
                const isDefault = (account.accountId === defaultAccount)
                const setDefault = () => dispatch(setDefaultAccount(account.accountId))
                return <li key={account.accountId} className="list-group-item">
                    <div className="d-flex">
                        <p className="my-0" style={{ width: "90px" }}>**** {account.mask}</p>
                        <p className="my-0 text-muted">{account.bankName}</p>
                        <div style={{ width: "80px" }} className="ms-auto text-end">
                            {(!isDefault) && <button className="btn btn-sm m-0 p-0" onClick={setDefault}>Set default</button>}
                        </div>
                    </div>
                </li>
            })
        return <ul className="list-group mb-3"> {listItems} </ul>
    }

    const saveLegalName = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!!name)
            await dispatch(setLegalName(name))
    }

    const requiredInfo: JSX.Element = (<div className="col-md-4 d-flex flex-column justify-content-between">
        <h3>Required info</h3>
        <div className="card">
            <div className="card-body">
                <p className="text-muted">ℹ️ When making a bank transfer we need to pass on your legal name. For that reason please make sure to enter it correctly below.<br /><br />If, for whatever reason, you need to change it in the future please contact support.</p>
                <form onSubmit={saveLegalName}>
                    <div className="form-group">
                        <input type="text" className="form-control my-2" placeholder="Your legal name..." id="legalName" value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <button type="submit" className="btn btn-primary w-100" disabled={!name}>That's me!</button>
                </form>
            </div>
        </div>
    </div>)

    const hasAccounts = Object.keys(accounts).length > 0

    if (isLoading) return <LoadingPlaceholder />

    return (
        <div id="dashboard" className="d-flex flex-column" style={{ minHeight: '100vh' }}>
            <div className="container my-4 flex-grow-1 flex-column">
                {!!(data?.error) && <div className='alert alert-danger text-center mx-auto'>ERRR{data.error}</div>}

                {/* Products Grid */}
                <section className="mb-5">
                    <div className="row">
                        <div className="col-md-8 d-flex flex-column ">
                            <div>
                                <h3>Products</h3>
                                {productsView()}
                            </div>
                            <button className="btn btn-primary w-100 mt-2 mb-5" onClick={orderButtonHandler} disabled={!Object.keys(accounts).length || isLoading}>
                                {placingOrder
                                    ? (<> <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Processing... </>)
                                    : ('Order')
                                }
                            </button>
                        </div>
                        {/* Connected Bank Accounts */}
                        {!legalName
                            ? requiredInfo
                            : <div className="col-md-4 d-flex flex-column justify-content-between">
                                <div>
                                    <h3>Connected accounts</h3>
                                    {hasAccounts
                                        ? accountsView()
                                        : <p>Click the button below to connect your first account.</p>
                                    }
                                    {!!token &&
                                        <LaunchLink token={token} userId={uid} itemId={null} />
                                    }
                                </div>
                                <button className={(Object.keys(accounts).length > 0) ? "btn btn-outline-secondary mt-3" : "btn btn-primary mt-3"} onClick={initiateLink}>Connect Bank Account</button>
                                <p className="text-white mt-2">ℹ️ We do not store your bank account details beyond what is required for the transaction.</p>
                            </div>
                        }
                    </div>
                </section>

                {(Object.keys(orders).length > 0) &&
                    <section className="d-flex flex-grow-1 flex-column">
                        <h3>Your Orders</h3>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Plan</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            {ordersView()}
                        </table>
                    </section>
                }

            </div>
            <Footer />
        </div>
    )
}

export default Dashboard
