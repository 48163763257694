import { Product } from "../types"

const ProductView = ({ isSelected, product, selectHandler }: {
    isSelected: boolean
    product: Product,
    selectHandler: (product: Product) => void
}) => {
    const cardClasses = isSelected
        ? "card border-0 highlight"
        : "card border-0"
    const productIcon = (): JSX.Element => {
        let path: string
        if (product.id === 1 || product.id === 3)
            path = isSelected ? "/assets/images/trophy-highlight.svg" : "/assets/images/trophy.svg"
        else 
            path = isSelected ? "/assets/images/medal-highlight.svg" : "/assets/images/medal.svg"
        return <img src={path} alt={`${product.name} icon`} style={{ width: "32px", height: "32px" }} />
    }
    return <div className="col-md-3 mb-4" onClick={() => selectHandler(product)}>
        <div className={cardClasses}>
            <div className="card-body">
                <h5 className="card-title text-black">{product.name}</h5>
                <div className="d-flex justify-content-between">
                    <p className={isSelected ? "card-text text-white align-self-center" : "card-text text-secondary align-self-center"}><strong>${product.price}</strong></p>
                    {productIcon()}
                </div>
            </div>
        </div>
    </div>
}

export default ProductView