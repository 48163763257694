import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer'
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table'
import goat from '../assets/images/GOAT3000.png'

Font.register({ family: 'BebasNeue', src: '/assets/fonts/BebasNeue-Regular.ttf' })

// Create styles
const styles = StyleSheet.create({
    page: { padding: 48, display: 'flex', flexDirection: 'column' },
    header: { fontFamily: 'BebasNeue', fontSize: 24, fontWeight: 'bold' },
    tableHeader: { fontFamily: 'BebasNeue', fontSize: 18, marginHorizontal: 16, marginVertical: 8 },
    paragraph: { fontSize: 12 },
    tableParagraph: { fontSize: 12, marginHorizontal: 16 },
    section: { padding: 16, marginBottom: 24 },
    highlightSection: { padding: 24, backgroundColor: '#cd201f', color: 'white' },
})

interface ReceiptProps {
    amount: number
    legalName: string
    orderId: string
    phoneNumber: string
    product: string
    transferId: string
    transferTimestamp: number
}

// Create Document Component
export const Receipt = ({ amount, legalName, orderId, phoneNumber, product, transferId, transferTimestamp }: ReceiptProps) => {
    const receiptDate = formatTimestamp(transferTimestamp)

    return <Document
        language='en'
        pageLayout='singlePage'
        title={`ParlayP Sports Receipt for order: ${orderId}`}
    >
        <Page
            orientation='portrait'
            size='LETTER'
            style={styles.page}
        >
            <View id='letterhead' style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 24,
            }}>
                <View>
                    <Text style={styles.paragraph}>Bank Transfer Receipt</Text>
                    <Text style={[styles.header, { color: '#cd201f' }]}>THE SPORTS NEWSLETTER, LLC</Text>
                    <Text style={styles.paragraph}>team@thesportsnewsletter.com</Text>
                </View>
                <View>
                    <Image src={goat} style={{ maxHeight: 64, maxWidth: 64 }} />
                </View>
            </View>
            <View id='header' style={[styles.section, { backgroundColor: '#f6d9da', display: 'flex', flexDirection: 'row' }]}>
                <View style={{ flexGrow: 1 }}>
                    <Text style={styles.paragraph}>Transfer date: {receiptDate}</Text>
                    <Text style={styles.paragraph}>Amount: ${amount.toFixed(2)}</Text>
                    <Text style={styles.paragraph}>Transfer type: Debit</Text>
                </View>
                <View style={{ flexShrink: 1 }}>
                    <Text style={[styles.paragraph, { textAlign: 'right' }]}>Transfer ID</Text>
                    <Text style={styles.paragraph}>{orderId}</Text>
                </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', marginBottom: 24 }}>
                <View style={{ flexGrow: 1 }}>
                    <Text style={[styles.header, { color: '#cd201f' }]}>Sender</Text>
                    <Text style={styles.paragraph}>{legalName}</Text>
                    <Text style={styles.paragraph}>{phoneNumber}</Text>
                </View>
                <View style={{ flexShrink: 1 }}>
                    <Text style={[styles.header, { color: '#cd201f' }]}>Receiver</Text>
                    <Text style={styles.paragraph}>THE SPORTS NEWSLETTER, LLC</Text>
                    <Text style={styles.paragraph}>123 Anywhere St.,</Text>
                    <Text style={styles.paragraph}>Any City, ST 12345</Text>
                    <Text style={styles.paragraph}>team@thesportsnewsletter.com</Text>
                </View>
            </View>
            <View style={{ flexGrow: 1 }}>
                <Text style={styles.header}>Order Summary</Text>
                <Table>
                    <TH style={{ backgroundColor: '#e9e9ea' }}>
                        <TD style={{ border: 'none' }}><Text style={styles.tableHeader}>QTY</Text></TD>
                        <TD style={{ border: 'none' }}><Text style={styles.tableHeader}>Product</Text></TD>
                        <TD style={{ border: 'none' }}><Text style={[styles.tableHeader, { textAlign: 'right', marginLeft: 'auto' }]}>Amount</Text></TD>
                    </TH>
                    <TR style={{ paddingVertical: 8, borderBottom: '3px solid #cd201f' }}>
                        <TD style={{ border: 'none' }}><Text style={styles.tableParagraph}>1</Text></TD>
                        <TD style={{ border: 'none', }}><Text style={styles.tableParagraph}>{product}</Text></TD>
                        <TD style={{ border: 'none', alignContent: 'flex-end' }}><Text style={[styles.tableParagraph, { textAlign: 'right', marginLeft: 'auto' }]}>${amount}</Text></TD>
                    </TR>
                    <TR style={{ paddingVertical: 8, display: 'flex' }}>
                        <TD style={{ border: 'none' }}></TD>
                        <TD style={{ border: 'none', flexGrow: 1 }}><Text style={[styles.tableParagraph, { marginLeft: 'auto' }]}>Subtotal</Text></TD>
                        <TD style={{ border: 'none', alignContent: 'flex-end' }}><Text style={[styles.tableParagraph, { textAlign: 'right', marginLeft: 'auto' }]}>${amount}</Text></TD>
                    </TR>
                    <TR style={{ paddingVertical: 8, display: 'flex' }}>
                        <TD style={{ border: 'none' }}></TD>
                        <TD style={{ border: 'none', flexGrow: 1 }}><Text style={[styles.tableParagraph, { marginLeft: 'auto' }]}>Fee</Text></TD>
                        <TD style={{ border: 'none', alignContent: 'flex-end' }}><Text style={[styles.tableParagraph, { textAlign: 'right', marginLeft: 'auto' }]}>$0.00</Text></TD>
                    </TR>
                    <TR style={[styles.highlightSection, { paddingVertical: 8, paddingHorizontal: 0, display: 'flex' }]}>
                        <TD style={{ border: 'none' }}></TD>
                        <TD style={{ border: 'none', flexGrow: 1 }}><Text style={[styles.header, { marginLeft: 'auto' }]}>Total</Text></TD>
                        <TD style={{ border: 'none', alignContent: 'flex-end' }}><Text style={[styles.tableParagraph, { textAlign: 'right', marginLeft: 'auto', fontWeight: 'bold' }]}>${amount}</Text></TD>
                    </TR>
                </Table>
            </View>
            <View id='footer' style={[styles.highlightSection, { textAlign: 'center' }]}>
                <Text style={[styles.header, { color: 'white' }]}>ParlayP Sports Newsletter, LLC</Text>
                <Text style={styles.paragraph}>team@thesportsnewsletter.com</Text>
            </View>
        </Page>
    </Document >
}

function formatTimestamp(timestamp: number) {
    const dtFormat = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'short',
    })

    return dtFormat.format(new Date(timestamp))
}