import { useState, useEffect } from "react"
import { getFirebase } from "../services/firebase"
import { getAuth, onAuthStateChanged } from "firebase/auth"

export default function useAuthListener() {
    const [checkingStatus, setCheckingStatus] = useState(true)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [uid, setUid] = useState('')

    const auth = getAuth()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setUid(user?.uid ?? '')
            setPhoneNumber(user?.phoneNumber ?? '')
            setIsLoggedIn(user !== null)
            setCheckingStatus(false)
        })
    }, [auth])

    return {
        checkingStatus,
        isLoggedIn,
        phoneNumber,
        uid,
    }
}


export const logOut = () => {
    const app = getFirebase()
    if (app) {
        const auth = getAuth(app)
        auth.signOut()
    }
}