import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { UserDataType } from "../types"

import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import {
    observeUserData,
    setDefaultAccount as apiSetDefaultAccount,
    setLegalName as apiSetLegalName,
} from "./firebase"
import { debugPrint } from "../utils/helpers"

export const setDefaultAccount = createAsyncThunk('accounts/setDefaultAccount', async (accountId: string): Promise<void> => {
    return apiSetDefaultAccount(accountId)
})

export const setLegalName = createAsyncThunk('accounts/setLegalName', async (legalName: string): Promise<void> => {
    return apiSetLegalName(legalName)
})

const initialState: UserDataType = {
    accounts: {},
    orders: {}
}

const accountSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        setAccounts: (state, action) => { state.accounts = action.payload },
    },
    extraReducers: (builder) => {
        builder.addCase(setDefaultAccount.pending, (state, _) => {
            state.error = undefined
        })
        builder.addCase(setDefaultAccount.fulfilled, (state) => {
            state.error = undefined
        })
        builder.addCase(setDefaultAccount.rejected, (state, action) => {
            debugPrint(JSON.stringify(action.error), 'error')
            state.error = "An error occurred while setting default account. Please contact support if the issue persists."
        })
        builder.addCase(setLegalName.pending, (state, _) => {
            state.error = undefined
        })
        builder.addCase(setLegalName.fulfilled, (state) => {
            state.error = undefined
        })
        builder.addCase(setLegalName.rejected, (state, action) => {
            debugPrint(JSON.stringify(action.error), 'error')
            state.error = "An error occurred while saving your legal name. Please contact support if the issue persists."
        })
    }
})

export const { setAccounts } = accountSlice.actions

export default accountSlice.reducer

export const userDataSlice = createApi({
    reducerPath: 'userData',
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        userData: builder.query<UserDataType, string>({
            queryFn: async () => { return { data: initialState } },
            onCacheEntryAdded: async (userID: string, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
                try {
                    if (!userID) return
                    await cacheDataLoaded

                    const onUpdate = (data: UserDataType) => {
                        updateCachedData((draft) => {
                            draft.accounts = data.accounts
                            draft.defaultAccount = data.defaultAccount
                            draft.email = data.email
                            draft.legalName = data.legalName
                            draft.orders = data.orders
                        })
                    }
                    const cancellable = observeUserData(userID, onUpdate)

                    await cacheEntryRemoved
                    cancellable()
                } catch (error) {
                    debugPrint(JSON.stringify(error), 'error')
                    updateCachedData((draft) => {
                        draft.error = "An internal server error has occurred. Please contact support if the issue persists."
                    })
                }
            }
        }),
    }),
})

export const { useUserDataQuery } = userDataSlice

