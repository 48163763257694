type LogLevel = 'debug' | 'info' | 'warn' | 'error'

export function debugPrint(message: string, level: LogLevel = 'debug'): void {
    if (process.env.PROJECT_ID === 'parlay-p-sports-newsletter') return

    switch (level) {
        case 'debug':
            console.debug(message)
            break
        case 'info':
            console.info(message)
            break
        case 'warn':
            console.warn(message)
            break
        case 'error':
            console.error(message)
            break
        default:
            console.log(message)
    }
}