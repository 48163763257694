import { logOut } from "./utils/authListener"

const Footer: React.FC = () => {
    return (<div className="container py-5 d-flex flex-column flex-md-row justify-content-between flex-shrink-0" id="footer">
        <a href="https://www.parlayp.com/terms-conditions/" target="_blank" className="btn btn-text btn-sm px-4 text-white">Terms & Conditions</a>
        <a href="https://www.thesportsnewsletter.com/" className="btn btn-text btn-sm px-4 text-white" target="_blank" rel="noopener noreferrer">© 2024 <u>The Sports Newsletter, LLC</u></a>
        <button onClick={logOut} className="btn btn-outline-light btn-sm px-4">Log Out</button>
    </div>)
}

export default Footer