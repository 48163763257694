import './App.css'
import Dashboard from './Dashboard'
import Login from './LoginPage'
import Order from './Order'
import LoadingPlaceholder from './components/LoadingPlaceholder'
import PageNotFound from './components/PageNotFound'
import ProtectedRoute from './utils/ProtectedRoute'
import useAuthListener from './utils/authListener'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

function App() {
  const { isLoggedIn, checkingStatus } = useAuthListener()
  if (checkingStatus) return (<LoadingPlaceholder />)

  return <Router>
    <Routes>
      <Route path="/login" element={<ProtectedRoute loggedIn={!isLoggedIn} redirectTo='/'><Login /></ProtectedRoute>} />
      <Route path="/orders/:orderId" element={<ProtectedRoute loggedIn={isLoggedIn}><Order /></ProtectedRoute>} />
      <Route path="/" element={<ProtectedRoute loggedIn={isLoggedIn}><Dashboard /></ProtectedRoute>} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </Router>
}

export default App
