import { configureStore } from "@reduxjs/toolkit"

import accountsReducer, { userDataSlice } from "./services/accountsSlice"

export const store = configureStore({
    reducer: {
        accounts: accountsReducer,
        userData: userDataSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userDataSlice.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch