import { Navigate, redirect, useLocation, useSearchParams } from "react-router-dom"
import { debugPrint } from "./helpers"

interface ProtectedRouteProps {
  loggedIn: boolean
  children: JSX.Element
  redirectTo?: string
}

const ProtectedRoute = ({ loggedIn, children, redirectTo }: ProtectedRouteProps): JSX.Element => {
  const location = useLocation()
  const [searchParams, _] = useSearchParams()

  if (!loggedIn) {
    const email = searchParams.get('email')
    let redirectPath = redirectTo ?? "/login"
    if (email) {
      debugPrint(`[ProtectedRoute] Setting user email to ${email}`, 'info')
      localStorage.setItem('ue', email) // user email
      redirectPath += '?email=' + email
    }

    return <Navigate to={redirectPath} replace state={{ from: location }} />
  }

  return children
}

export default ProtectedRoute